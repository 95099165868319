import React from "react"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import SecondaryHeader from "../../components/SecondaryHeader"
import CustomLink from "../../components/CustomLink"
import SupportCenterTopicWrapper from "../../components/SupportCenterLayout"

const ThePatientsDashboardPage = () => {
  return (
    <Layout>
      <Seo title={`Easy to use cloud platform`} />
      <SecondaryHeader title={<>Support Center</>} />

      <div id="support-center-page">
        <section className="support-center">
          <div style={{ paddingBottom: "50px" }}></div>

          <SupportCenterTopicWrapper>
            <div className="support-center__topic-right">
              <h3 className="mb-4">Understanding the Patients Dashboard</h3>

              <p>
                Keep your patient's treatment plan on track with ease by
                managing, viewing, and editing all patient-related information
                from the Patients Dashboard.
              </p>

              <div className="mb-4" />

              <h5 id="Adding a New Patient">
                Title: <strong>Adding a New Patient</strong>
              </h5>

              <div className="mb-4" />

              <p>
                <strong>Step 1</strong> - Sign in to AlignerBase.
              </p>

              <div className="mb-4" />

              <p>
                Having trouble signing in? View{" "}
                <CustomLink
                  title="Signing in to AlignerBase Dashboard."
                  link="/support-center/signing-into-your-dashboard"
                />
              </p>

              <div className="mb-4" />

              <p>
                <strong>Step 2</strong> - From the main dashboard, click on{" "}
                <strong>Patients</strong> from the left side panel.
              </p>

              <div className="mb-4" />

              <img src="https://alignerbase-public.s3.us-east-2.amazonaws.com/support-center/5.jpg" />

              <div className="mb-4" />

              <p>
                <strong>Step 3</strong> - The Patients section will list down
                all your patients. To add a new patient, click on “+ Add
                Patient” button which is present on the top-right.
              </p>

              <div className="mb-4" />

              <p>
                <strong>Step 4</strong> - Add in patient's information in the
                fields displayed.
              </p>

              <div className="mb-4" />

              <img src="https://alignerbase-public.s3.us-east-2.amazonaws.com/support-center/5a.jpg" />

              <div className="mb-4" />

              <p>
                Mandatory fields are the first name and last name. Rest of the
                fields are optional and can be left out.
              </p>

              <div className="mb-4" />

              <p>
                <strong>Step 5</strong> - Once you fill out all the information,
                you can either assign this new patient to another doctor in your
                facility by pressing “Assign Patient to Other Facility Member”
                or simply add it to your own list by clicking on “Create New
                Patient” button present on the bottom right.
              </p>

              <div className="mb-4" />

              <img src="https://alignerbase-public.s3.us-east-2.amazonaws.com/support-center/5b.jpg" />

              <div className="mb-4" />

              <h5 id="Deleting a Patient from the list">
                Title: <strong>Deleting a Patient from the list</strong>
              </h5>

              <div className="mb-4" />

              <p>
                <strong>Step 1</strong> - Sign in to AlignerBase.
              </p>

              <div className="mb-4" />

              <p>
                Having trouble signing in? View{" "}
                <CustomLink
                  title="Signing in to AlignerBase Dashboard."
                  link="/support-center/signing-into-your-dashboard"
                />
              </p>

              <div className="mb-4" />

              <p>
                <strong>Step 2</strong> - From the main dashboard, click on{" "}
                <strong>Patients</strong> from the left side panel.
              </p>

              <div className="mb-4" />

              <p>
                <strong>Step 3</strong> - Locate the patient you want to delete
                either by searching for the patient or the doctor the patient is
                assigned to.
              </p>

              <div className="mb-4" />

              <p>
                <strong>Step 4</strong> - After locating, you can click on
                “Delete” button present in the patients row under the Actions
                column.
              </p>

              <div className="mb-4" />

              <img src="https://alignerbase-public.s3.us-east-2.amazonaws.com/support-center/5c.jpg" />

              <div className="mb-4" />
            </div>
          </SupportCenterTopicWrapper>
        </section>
      </div>
    </Layout>
  )
}

export default ThePatientsDashboardPage
